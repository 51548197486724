<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

export default {
  name: 'weChatMoney',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/redEnvelop/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },

        {
          label: '',
          type: 'text',
          key: ''
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length
        },
        {
          dataIndex: 'maxPrice',
          title: '金额',
          align: 'left',
          customRender: function(text, records) {
            return records.sendType == '0' ? text : `${records.minPrice + ' ~ ' + text}`
          }
        },
        {
          dataIndex: 'receiveSum',
          title: '领取次数',
          align: 'left'
        },
        {
          dataIndex: 'receivePrice',
          title: '累计发放（元）',
          align: 'left',
          customRender: function(text, records) {
            return (
              <div style="color:#093C3D;font-weight:500">{`${records.receivePrice ? records.receivePrice : 0}/${
                records.allPrice
              }（${records.allPrice ? ((records.receivePrice / records.allPrice) * 100).toFixed(0) : '0'}%）`}</div>
            )
          },
          onExport: function(text, records) {
            return `${records.receivePrice ? records.receivePrice : 0}/${records.allPrice}（${
              records.allPrice ? ((records.receivePrice / records.allPrice) * 100).toFixed(0) : '0'
            }%）`
          }
        },
        {
          dataIndex: 'startDate',
          title: '有效期',
          align: 'left',
          customRender: function(text, records) {
            return text && `${text}~${records.endDate}`
          },
          onExport: function(text, records) {
            return text && `${text}~${records.endDate}`
          }
        },
        {
          dataIndex: 'enabled',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '上架',
              value: '1'
            },
            {
              text: '下架',
              value: '0'
            }
          ],
          onExport: records => {
            return ['下架', '上架'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 1 ? '上架' : '下架',
              color: data == 1 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              //       //   {
              //       //     name: '二维码',
              //       //     onClick: () => {
              //       //       if (!records.qrCode) {
              //       //         this.$message.warning('此条数据暂无二维码！')
              //       //       } else {
              //       //         const a = document.createElement('a')
              //       //         a.href = records.qrCode
              //       //         a.download = '二维码.png'
              //       //         a.style.display = 'none'
              //       //         a.target = '_blank'
              //       //         document.body.appendChild(a)
              //       //         a.click()
              //       //         document.body.removeChild(a)
              //       //       }
              //       //     }
              //       //   },
              {
                display: records.bodyStatus == '1',
                name: '设置主体',
                onClick: () =>
                  this.$router.push(`/marketingCenter/setMain?moduleType=${records.usedSystem}&redId=${records.id}`)
              },
              {
                name: '领取情况',
                display: true,
                onClick: () => {
                  this.$router.push('/marketingCenter/weChatMoneyReceive?id=' + records.id)
                }
              },
              {
                name: records.enabled == 0 ? '上架' : '下架',
                display: true,
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/redEnvelop/${records.enabled == 0 ? 'enable' : 'disable'}/${records.id}`
                  })
                }
              },
              {
                name: '编辑',
                display: true,
                onClick: () => this.$router.push('/marketingCenter/weChatMoneyDetail?id=' + records.id)
              },
              {
                name: '删除',
                display: true,
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: `/redEnvelop/delete/${records.id}`
                  })
                }
              }
            ].filter(e => e.display)
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/marketingCenter/weChatMoneyDetail')
        }
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量删除',
        //       onClick: data => {
        //         let idList = data.map(e => e.id)
        //         let ids = idList.toString()
        //         return api.command.delPut.call(this, {
        //           url: '/base/farmCouponRule/del?ids=' + ids
        //         })
        //       }
        //     },
        //     {
        //       name: '批量上架',
        //       onClick: data => {
        //         let idList = data.map(e => e.id)
        //         let ids = idList.toString()
        //         return api.command.edit.call(this, {
        //           url: '/base/farmCouponRule/up?ids=' + ids
        //         })
        //       }
        //     },
        //     {
        //       name: '批量下架',
        //       onClick: data => {
        //         let idList = data.map(e => e.id)
        //         let ids = idList.toString()
        //         return api.command.edit.call(this, {
        //           url: '/base/farmCouponRule/down?ids=' + ids
        //         })
        //       }
        //     }
        //   ]
        // }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
